module Main exposing (..)

import Api
import ApiWs
import Browser exposing (UrlRequest)
import Browser.Navigation as Nav
import Context exposing (runContext)
import Data.Amount as Amount exposing (Amount(..), coin, coinCustom, getAmount, getCustomAmount, isCustomAmount, slider)
import Data.Client exposing (Client)
import Data.Currency exposing (Currency(..), ExchangeRates)
import Data.Donation as Donation exposing (DonationId, applyRequireDonationReceiptAt, exceedsThresholdAmount)
import Data.Payment exposing (Method(..))
import Data.Payment.Constrains exposing (PaymentConstrains)
import Data.Payment.Provider exposing (PaymentResponse(..))
import Data.Receipt exposing (receiptFormAnyErrors)
import Flags
import Html exposing (Html, button, div, h1, h2, node, p)
import Html.Attributes exposing (class, classList, download, href, target)
import Html.Events exposing (onClick)
import Http.Extra
import Invoice
import Json.Decode as Decode
import Json.Encode as Encode
import Language exposing (Language, Translation)
import List.Extra
import Maybe.Extra
import Platform.Cmd exposing (none)
import Ports exposing (copyToClipboard, decodePortValue, portReceive, toPortMsg)
import QrCode exposing (qrCode)
import Task
import Toast exposing (Toast)
import UI exposing (checkboxWithDescription_, focusVisible, icon, input, labelEl, spinner, text, translate)
import Url exposing (Url)
import Url.Parser exposing ((<?>))
import Url.Parser.Query
import Validated exposing (hasError, nonEmptyString, setValue, wrap)
import Websocket



-- MODEL


type alias Env =
    { api : Api.Config
    , language : Language
    , development : Bool
    , embedded : Bool
    }


type alias Model =
    { url : Url
    , key : Nav.Key
    , route : Route
    , donationForm : Donation.Form
    , paymentStatus : PaymentStatus
    , exchangeRates : Maybe ExchangeRates
    , client : Maybe Client
    , donationId : Maybe String
    , toast : Maybe (Toast Translation)
    , env : Env
    , ws : Websocket.Model
    }


defaultModel : Url -> Nav.Key -> Model
defaultModel url key =
    { url = url
    , key = key
    , route = Home
    , donationForm =
        { amount = setValue (Amount 1)
        , paymentMethod = Lightning
        , receipt = Nothing
        }
    , paymentStatus = PaymentNotAsked
    , toast = Nothing
    , exchangeRates = Nothing
    , env =
        { api =
            { url = ""
            , key = ""
            , origin = ""
            }
        , language = Language.En
        , development = False
        , embedded = False
        }
    , client = Nothing
    , donationId = Nothing
    , ws = Websocket.init
    }



-- Helpers


defaultConstrains : PaymentConstrains
defaultConstrains =
    { min = 1000
    , max = 10000
    }


getMinAmount : Model -> Int
getMinAmount =
    getAmountBoundary defaultConstrains .min


getMaxAmount : Model -> Int
getMaxAmount =
    getAmountBoundary defaultConstrains .max


getAmountBoundary : PaymentConstrains -> (PaymentConstrains -> Int) -> Model -> Int
getAmountBoundary default fn model =
    -- Get the min amount from the client configuration for the payment method
    case model.client of
        Nothing ->
            fn default

        Just client ->
            case model.donationForm.paymentMethod of
                Bitcoin ->
                    client.methods.bitcoin
                        |> Maybe.map fn
                        |> Maybe.withDefault (fn default)

                Lightning ->
                    client.methods.lightning
                        |> Maybe.map fn
                        |> Maybe.withDefault (fn default)


getPaymentConstrains : Model -> Client -> PaymentConstrains
getPaymentConstrains model client =
    case model.donationForm.paymentMethod of
        Bitcoin ->
            client.methods.bitcoin
                |> Maybe.withDefault defaultConstrains

        Lightning ->
            client.methods.lightning
                |> Maybe.withDefault defaultConstrains



--


type Route
    = Home
    | ReceiptRoute
    | Payment
    | Done DonationId
    | ClientTokenMissing


type PaymentStatus
    = PaymentNotAsked
    | InvoiceRequested
    | PaymentLink PaymentResponse
    | PaymentDone Donation.Status


type Msg
    = Noop
    | UrlChage Url
    | UrlRequest UrlRequest
    | PortMsg Ports.Msg
    | UpdateDonationForm Donation.Form
    | ToggleReceipt Bool
    | Navigate Route
    | Back
    | Next
    | ApiMsg Api.Msg
    | SetToast (Maybe (Toast Translation))
    | SetPaymentMethod Data.Payment.Method
    | GetInvoice
    | RunCmd (Cmd Msg)
    | WebSocketMsg Websocket.Msg
    | SendApiMsg ApiWs.Pub



-- Main


main : Program Encode.Value Model Msg
main =
    Browser.application
        { init = init
        , update = update
        , view = document
        , subscriptions = subscriptions
        , onUrlChange = UrlChage
        , onUrlRequest = UrlRequest
        }


document : Model -> Browser.Document Msg
document model =
    { title =
        case model.client of
            Nothing ->
                "BITCOIN4GOOD"

            Just c ->
                "B4G: " ++ c.name
    , body =
        [ view model
        ]
    }


subscriptions : Model -> Sub Msg
subscriptions _ =
    Sub.batch
        [ Websocket.wsPortReceive_
            (SetToast << Just << Toast.error << Language.always)
            WebSocketMsg
        , portReceive (decodePortValue PortMsg)
        ]


init : Encode.Value -> Url -> Nav.Key -> ( Model, Cmd Msg )
init flagsJson url key =
    let
        flagsRes =
            Decode.decodeValue Flags.decodeFlags flagsJson
                |> Result.mapError Decode.errorToString
                |> Result.andThen
                    (\flags ->
                        case flags.clientToken of
                            Nothing ->
                                Err <|
                                    translate { env = { language = flags.language } } <|
                                        { en = """Client token missing.
                                    Please add it to the url as a query parameter: ?clientToken=..."""
                                        , de = """Client token fehlt.
                                    Bitte fügen Sie es als Abfrageparameter zur URL hinzu: ?clientToken=..."""
                                        , fr = """Client token manquant.
                                    S'il te plaît, ajoute-le comme paramètre de requête à l'URL : ?clientToken=..."""
                                        }

                            Just clientToken ->
                                Ok
                                    { api =
                                        { url = flags.apiUrl
                                        , key = clientToken
                                        , origin = flags.origin
                                        }
                                    , language = flags.language
                                    , development = flags.development
                                    , embedded = flags.embedded
                                    }
                    )
    in
    case flagsRes of
        Err err ->
            let
                model =
                    defaultModel url key
            in
            ( { model | toast = Just <| Toast.error <| Language.always err }
            , Cmd.none
            )

        Ok flags ->
            let
                model =
                    defaultModel url key
                        |> (\m -> { m | env = flags })
            in
            ( model
            , Cmd.batch
                [ msgCmd (UrlChage model.url)
                , Cmd.map ApiMsg (runContext Api.getExchangeRates model.env.api)
                , Websocket.connect
                ]
            )



-- UPDATE


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        Noop ->
            ( model, none )

        RunCmd cmd ->
            ( model, cmd )

        UrlChage url ->
            handleUrlUpdate url model

        UrlRequest urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    ( model
                    , Nav.pushUrl model.key (Url.toString url)
                    )

                Browser.External url ->
                    ( model
                    , Nav.load url
                    )

        UpdateDonationForm df ->
            let
                foo : Maybe (Donation.Form -> Donation.Form)
                foo =
                    Maybe.map2 applyRequireDonationReceiptAt model.client model.exchangeRates
            in
            ( { model
                | donationForm =
                    foo
                        |> Maybe.map (\fn -> fn df)
                        |> Maybe.withDefault df
              }
            , Cmd.none
            )

        ToggleReceipt bool ->
            let
                donationFormModel =
                    model.donationForm
            in
            ( { model
                | donationForm =
                    { donationFormModel
                        | receipt =
                            if bool then
                                Just
                                    { fullname = setValue ""
                                    , fulladdress = setValue ""
                                    , email = setValue ""
                                    , privateAsset = setValue False
                                    , minHoldTime = setValue False
                                    }

                            else
                                Nothing
                    }
              }
            , Cmd.none
            )

        Back ->
            ( model, msgCmd (handleBackRoute model) )

        Next ->
            ( model, msgCmd (handleNextRoute model) )

        Navigate route ->
            handleRoutes model route

        ApiMsg apiMsg ->
            handleApiMsg apiMsg model

        SetToast err ->
            ( { model | toast = err }, Cmd.none )

        PortMsg pm ->
            handlePortMsg pm model

        GetInvoice ->
            ( { model | paymentStatus = InvoiceRequested }
            , Cmd.map ApiMsg (runContext (Api.createInvoice (Donation.toDonation model.donationForm)) model.env.api)
            )

        SetPaymentMethod method ->
            let
                donationForm =
                    model.donationForm |> (\df -> { df | paymentMethod = method })
            in
            ( { model | donationForm = donationForm }
            , Cmd.none
            )

        WebSocketMsg wsMsg ->
            handleWebSocketMsgUpdate wsMsg model
                |> continue (handleWebSocketMsg wsMsg)

        SendApiMsg send ->
            ( model
            , ApiWs.send send
            )


continue : (( Model, Cmd Msg ) -> ( Model, Cmd Msg )) -> ( Model, Cmd Msg ) -> ( Model, Cmd Msg )
continue fn ( model, cmd ) =
    let
        ( model_, cmd_ ) =
            fn ( model, cmd )
    in
    ( model_, Cmd.batch [ cmd, cmd_ ] )


handleWebSocketMsgUpdate : Websocket.Msg -> Model -> ( Model, Cmd Msg )
handleWebSocketMsgUpdate msg model =
    let
        ( wsModel, wsCmds ) =
            Websocket.update msg model.ws
    in
    ( { model | ws = wsModel }, Cmd.map WebSocketMsg wsCmds )


handleWebSocketMsg : Websocket.Msg -> ( Model, Cmd Msg ) -> ( Model, Cmd Msg )
handleWebSocketMsg wsMsg ( model, _ ) =
    case wsMsg of
        Websocket.Open ->
            ( model
            , ApiWs.send
                (ApiWs.Init
                    { clientToken = model.env.api.key
                    , origin = model.env.api.origin
                    , donationId = model.donationId
                    }
                )
            )

        Websocket.Error ->
            ( { model
                | toast = Just <| Toast.error <| Language.always "Websocket error"
              }
            , Cmd.none
            )

        Websocket.Message msg ->
            case Decode.decodeString ApiWs.decoder msg of
                Ok sm ->
                    handleApiWsMsg sm model

                Err err ->
                    ( { model
                        | toast = Just <| Toast.error <| Language.always (Decode.errorToString err)
                      }
                    , Cmd.none
                    )

        _ ->
            ( model, Cmd.none )


handleUrlUpdate : Url -> Model -> ( Model, Cmd Msg )
handleUrlUpdate url model =
    Url.Parser.oneOf
        [ Url.Parser.map
            (Maybe.Extra.unwrap Home Done)
            (Url.Parser.s "success" <?> Url.Parser.Query.string "donationId")
        ]
        |> (\parser -> Url.Parser.parse parser url)
        |> Maybe.Extra.unwrap ( model, Cmd.none ) (handleRoutes model)


handlePortMsg : Ports.Msg -> Model -> ( Model, Cmd Msg )
handlePortMsg msg model =
    case msg of
        Ports.Copied ->
            ( { model
                | toast =
                    Just <|
                        Toast.success <|
                            { en = "Copied to clipboard"
                            , de = "In die Zwischenablage kopiert"
                            , fr = "Copié dans le presse-papiers"
                            }
              }
            , toPortMsg "toast" []
            )

        Ports.Toast ->
            ( { model | toast = Nothing }, Cmd.none )

        Ports.Missing portMessageTag ->
            ( { model
                | toast =
                    Just <|
                        Toast.error
                            { en = "Missing port msg for " ++ portMessageTag
                            , de = "Fehlende Port-Nachricht für " ++ portMessageTag
                            , fr = "Message de port manquante pour " ++ portMessageTag
                            }
              }
            , Cmd.none
            )

        Ports.DecodeError err ->
            ( { model | toast = Just <| Toast.error <| Language.always (Decode.errorToString err) }, Cmd.none )


handleApiMsg : Api.Msg -> Model -> ( Model, Cmd Msg )
handleApiMsg apiMsg model =
    case apiMsg of
        Api.GotPaymentResponse res ->
            handleHttpResult model
                res
                (\pay ->
                    ( { model
                        | paymentStatus = PaymentLink pay
                      }
                    , Cmd.none
                    )
                )

        Api.GetDonationStatus donationId ->
            ( { model | paymentStatus = PaymentNotAsked }
            , Cmd.map ApiMsg (runContext (Api.getDonationStatus donationId) model.env.api)
            )

        Api.GotDonationStatus res ->
            handleHttpResult model
                res
                (\li ->
                    ( { model
                        | route = Done li.donationId
                        , paymentStatus = PaymentDone li
                      }
                    , Cmd.none
                    )
                )

        Api.GotExchangeRates res ->
            handleHttpResult model
                res
                (\rates ->
                    ( { model | exchangeRates = Just rates }, Cmd.none )
                )


handleApiWsMsg : ApiWs.Sub -> Model -> ( Model, Cmd Msg )
handleApiWsMsg apiMsg model =
    case apiMsg of
        ApiWs.PaymentResponse pay ->
            ( { model | paymentStatus = PaymentLink pay }
              -- TODO: push donation url
            , case pay of
                LnPayUrl bolt11 ->
                    toPortMsg "bolt11" [ ( "bolt11", Encode.string bolt11 ) ]

                _ ->
                    Cmd.none
            )

        ApiWs.DonationStatus status ->
            ( { model
                | route = Done status.donationId
                , paymentStatus = PaymentDone status
              }
            , Nav.pushUrl model.key
                (Donation.donationUrl [ "success" ]
                    { clientToken = model.env.api.key
                    , donationId = status.donationId
                    }
                )
            )

        ApiWs.GotExchangeRates rates ->
            ( { model | exchangeRates = Just rates }, Cmd.none )

        ApiWs.Client client ->
            ( { model | client = Just client }, Cmd.none )

        ApiWs.DonationId id ->
            ( { model | donationId = Just id }, Cmd.none )

        ApiWs.Error err ->
            ( { model
                | toast = Just <| Toast.error <| Language.always err
              }
            , Cmd.none
            )


handleHttpResult : Model -> Result Http.Extra.Error value -> (value -> ( Model, Cmd msg )) -> ( Model, Cmd msg )
handleHttpResult model result handler =
    case result of
        Ok res ->
            handler res

        Err err ->
            ( { model | toast = Just <| Toast.error <| (Http.Extra.httpErrorToString err |> Language.always) }, Cmd.none )


msgCmd : Msg -> Cmd Msg
msgCmd msg =
    Task.perform identity (Task.succeed msg)



-- Routing
-- ! This is a mess and we should explore the catogory theory way of handling this propperly


{-| -}
handleBackRoute : Model -> Msg
handleBackRoute model =
    case model.route of
        Home ->
            Noop

        ReceiptRoute ->
            Navigate Home

        Payment ->
            Navigate
                (if Donation.hasReceipt model.donationForm then
                    ReceiptRoute

                 else
                    Home
                )

        Done _ ->
            Noop

        ClientTokenMissing ->
            Noop


{-| Check if the route has any validation errors.
-}
handleNextRoute : Model -> Msg
handleNextRoute model =
    let
        form =
            model.donationForm
    in
    -- Current route
    case model.route of
        Home ->
            let
                min =
                    getMinAmount model

                validatedAmount =
                    Amount.validateAmount model min form.amount
            in
            if hasError validatedAmount then
                UpdateDonationForm { form | amount = validatedAmount }

            else if Donation.hasReceipt form then
                Navigate ReceiptRoute

            else
                Navigate Payment

        ReceiptRoute ->
            case model.donationForm.receipt of
                Nothing ->
                    Navigate Payment

                Just receiptForm ->
                    let
                        validatedReceipt =
                            { receiptForm
                                | fullname = nonEmptyString receiptForm.fullname
                                , fulladdress = nonEmptyString receiptForm.fulladdress
                                , email = nonEmptyString receiptForm.email
                                , privateAsset = Validated.isChecked receiptForm.privateAsset
                                , minHoldTime = Validated.isChecked receiptForm.minHoldTime
                            }
                    in
                    if receiptFormAnyErrors validatedReceipt then
                        UpdateDonationForm { form | receipt = Just validatedReceipt }

                    else
                        Navigate Payment

        Payment ->
            Noop

        Done _ ->
            Noop

        ClientTokenMissing ->
            Noop


handleRoutes : Model -> Route -> ( Model, Cmd Msg )
handleRoutes model route =
    case ( route, model.paymentStatus ) of
        -- When done
        ( _, PaymentDone status ) ->
            ( { model | route = Done status.donationId }, Cmd.none )

        ( _, PaymentLink _ ) ->
            ( { model | route = route, paymentStatus = PaymentNotAsked }, Cmd.none )

        ( Home, _ ) ->
            ( { model | route = route }, Cmd.none )

        ( ReceiptRoute, _ ) ->
            ( { model | route = route }, Cmd.none )

        ( Payment, PaymentNotAsked ) ->
            let
                donation =
                    Donation.toDonation model.donationForm
            in
            ( { model
                | route = route
                , paymentStatus = InvoiceRequested
              }
            , Cmd.batch
                [ ApiWs.send (ApiWs.DonationRequest donation)
                , if donation.payment.method == Lightning then
                    toPortMsg "webln-init" []

                  else
                    Cmd.none
                ]
            )

        ( Payment, _ ) ->
            ( { model | route = route }, Cmd.none )

        ( Done did, _ ) ->
            ( { model | route = route }
            , Cmd.map ApiMsg (runContext (Api.getDonationStatus did) model.env.api)
            )

        ( ClientTokenMissing, _ ) ->
            ( model, Cmd.none )



-- TODO: Validate complete form and jump to invalid step
-- VIEW


view : Model -> Html Msg
view model =
    div
        [ class "h-screen text-black dark:text-white dark:bg-[#111617] overflow-y-auto"
        , if model.env.embedded then
            class "bg-transparent"

          else
            class "bg-[#FBFBFA]"
        ]
        [ node "style" [] [ Html.text getStyleSheet ]
        , div
            []
            [ -- View error
              case model.toast of
                Just toast ->
                    Toast.view { onClose = SetToast Nothing } toast (text model)

                Nothing ->
                    Html.text ""
            ]
        , div
            [ class "flex h-full w-full max-w-md flex-col items-center space-y-8 p-4 m-auto"
            ]
            [ viewTitle model
            , steps model
            , viewRoutes model
            , nextButton model
            , clientName model
            , Html.a
                [ class "text-center text-xs text-gray-500 leading-6 align-bottom pt-8 pb-4 cursor-pointer"
                , target "_blank"
                , href "https://www.bitcoin4good.de/"
                ]
                [ Html.text "Powered by"
                , logoWm
                ]
            ]
        ]


viewTitle : Model -> Html Msg
viewTitle model =
    case model.client of
        Just client ->
            case client.config.title of
                Just title ->
                    h1 []
                        [ Html.text title
                        ]

                Nothing ->
                    h1 []
                        [ text model
                            { en = "Help now - donate Bitcoin!"
                            , de = "Jetzt helfen - spende Bitcoin!"
                            , fr = "Aidez maintenant - faites un don en Bitcoin!"
                            }
                        ]

        Nothing ->
            h1 []
                [ text model
                    { en = "Initializing..."
                    , de = "Initialisierung..."
                    , fr = "Initialisation..."
                    }
                ]


clientName : Model -> Html Msg
clientName model =
    case model.client of
        Nothing ->
            UI.none

        Just client ->
            div
                [ class "flex flex-col space-y-2 items-center" ]
                [ div
                    [ class "text-xs text-gray-500" ]
                    [ Html.text client.name
                    ]
                ]


nxtBtn : { b | env : Language.LanguageContext a } -> Html Msg
nxtBtn model =
    button
        [ class "rounded-full bg-primary px-3.5 py-4 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-darker w-64"
        , focusVisible
        , Html.Events.onClick Next
        ]
        [ text model
            { en = "Next"
            , de = "Weiter"
            , fr = "Suivant"
            }
        ]


nextButton : Model -> Html Msg
nextButton model =
    case model.route of
        Home ->
            nxtBtn model

        ReceiptRoute ->
            nxtBtn model

        Payment ->
            UI.none

        Done _ ->
            UI.none

        ClientTokenMissing ->
            UI.none


viewRoutes : Model -> Html Msg
viewRoutes model =
    case model.route of
        ClientTokenMissing ->
            div []
                [ h2
                    []
                    [ text model
                        { en = "ClientToken Missing"
                        , de = "ClientToken fehlt"
                        , fr = "ClientToken manquant"
                        }
                    ]
                ]

        Home ->
            case ( model.client, model.exchangeRates ) of
                ( Just client, Just exchangeRates ) ->
                    viewHome model client exchangeRates

                ( _, _ ) ->
                    spinner

        ReceiptRoute ->
            viewReceiptForm model

        Payment ->
            case model.paymentStatus of
                PaymentNotAsked ->
                    div []
                        [ text model
                            { en = "Payment not asked"
                            , de = "Zahlung nicht angefordert"
                            , fr = "Paiement non demandé"
                            }
                        ]

                InvoiceRequested ->
                    div [ class "flex flex-col justify-center items-center space-y-4" ]
                        [ UI.spinner
                        , div
                            [ class "text-sm text-gray-500"
                            ]
                            [ text model
                                { en = "Requesting invoice..."
                                , de = "Rechnung wird angefordert..."
                                , fr = "Demande de facture..."
                                }
                            ]
                        , case model.donationForm.paymentMethod of
                            Bitcoin ->
                                bitcoinBtcLogo [ class "w-12" ]

                            Lightning ->
                                bitcoinLnLogo [ class "w-12" ]
                        ]

                PaymentLink payment ->
                    case payment of
                        CheckoutUrl url ->
                            div
                                [ class "h-full shadow-lg rounded-lg border border-gray-200 dark:border-gray-700"
                                ]
                                [ Html.iframe
                                    [ Html.Attributes.src url
                                    , class "w-[320px] h-[800px] rounded-lg"
                                    ]
                                    []
                                ]

                        LnPayUrl lnbc ->
                            div
                                [ class "flex flex-col justify-center items-center space-y-8"
                                ]
                                [ div
                                    [ class "flex flex-col space-y-2 items-center"
                                    ]
                                    [ div [ class "text-lg" ] [ spinner ]
                                    , div
                                        [ class "text-sm text-gray-500"
                                        ]
                                        [ text model
                                            { en = "Waiting for payment..."
                                            , de = "Warten auf Zahlung..."
                                            , fr = "En attente de paiement..."
                                            }
                                        ]
                                    ]
                                , div
                                    [ class "flex flex-col space-y-4 items-center"
                                    ]
                                    [ div
                                        [ onClick (RunCmd (copyToClipboard lnbc))
                                        , class "text-gray-200 bg-white p-4 rounded-lg"
                                        ]
                                        [ qrCode 3 lnbc ]
                                    , div
                                        [ class "text-sm text-gray-500 text-center"
                                        ]
                                        [ text model
                                            { en = "Scan the QR code or click it to copy the invoice to pay with your Lightning wallet."
                                            , de = "Scannen Sie den QR-Code oder klicken Sie darauf, um die Rechnung in Ihre Lightning-Wallet zu kopieren."
                                            , fr = "Scannez le code QR ou cliquez dessus pour copier la facture dans votre portefeuille Lightning."
                                            }
                                        ]
                                    , div
                                        [ class "flex flex-col space-y-2 items-center"
                                        ]
                                        [ Html.a
                                            [ href ("lightning:" ++ lnbc)
                                            , class "block rounded-full bg-primary px-6 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-darker w-full"
                                            , focusVisible
                                            , target "_top"
                                            ]
                                            [ text model
                                                { en = "Open in wallet"
                                                , de = "In Wallet öffnen"
                                                , fr = "Ouvrir dans le portefeuille"
                                                }
                                            ]
                                        , div [ class "text-xs text-gray-500" ]
                                            [ text model
                                                { en = "or"
                                                , de = "oder"
                                                , fr = "ou"
                                                }
                                            ]
                                        , UI.mapMaybe
                                            (\donationId ->
                                                button
                                                    [ class "block rounded-full bg-gray-100 dark:bg-gray-800 px-6 py-2.5 text-center text-sm font-semibold text-gray-500 dark:text-gray-400 shadow-sm hover:bg-gray-200 dark:hover:bg-gray-700 w-full"
                                                    , onClick (SendApiMsg (ApiWs.GetDonationStatus donationId))
                                                    ]
                                                    [ text model
                                                        { en = "Check payment status"
                                                        , de = "Zahlungsstatus prüfen"
                                                        , fr = "Vérifier le statut du paiement"
                                                        }
                                                    ]
                                            )
                                            model.donationId
                                        ]
                                    ]
                                ]

                PaymentDone _ ->
                    div []
                        [ text model
                            { en = "Payment done"
                            , de = "Zahlung abgeschlossen"
                            , fr = "Paiement effectué"
                            }
                        ]

        Done _ ->
            case model.paymentStatus of
                PaymentDone donationStatus ->
                    div
                        [ class "flex flex-col space-y-4 justify-center items-center" ]
                        [ logo
                        , p []
                            [ text model
                                { en = "Thank you for your donation!"
                                , de = "Vielen Dank für Ihre Spende!"
                                , fr = "Merci pour votre don!"
                                }
                            ]
                        , if donationStatus.hasReceipt && donationStatus.invoiceStatus == Invoice.Paid then
                            Html.a
                                [ href (Api.getDownloadLink model.env.api donationStatus.donationId)
                                , download ""
                                , target "_blank"
                                ]
                                [ button
                                    [ class "w-full block rounded-full bg-primary px-6 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-darker"
                                    , focusVisible
                                    ]
                                    [ text model
                                        { en = "Donation receipt"
                                        , de = "Spendenquittung"
                                        , fr = "Reçu de don"
                                        }
                                    ]
                                ]

                          else
                            UI.none
                        ]

                _ ->
                    UI.none


steps : Model -> Html Msg
steps model =
    let
        currentStep =
            List.Extra.findIndex (\( _, r ) -> r == model.route) (getSteps model)
                |> Maybe.withDefault 0
    in
    div []
        [ div
            [ class "flex justify-center items-center mb-4"
            ]
          <|
            List.intersperse
                (div
                    [ class "w-12 h-0.5 bg-gray-200 dark:bg-gray-700"
                    ]
                    []
                )
            <|
                List.indexedMap
                    (\index ( description, route ) ->
                        step
                            { index = index
                            , selected = model.route == route
                            , onClick =
                                if index < currentStep then
                                    Back

                                else if index > currentStep then
                                    Next

                                else
                                    Noop
                            , description = description
                            , done = index < currentStep
                            , disabled = paymendDone model.paymentStatus
                            }
                            model
                    )
                    (getSteps model)
        ]


paymendDone : PaymentStatus -> Bool
paymendDone paymentStatus =
    case paymentStatus of
        PaymentDone _ ->
            True

        _ ->
            False


getDonationId : PaymentStatus -> Maybe String
getDonationId arg1 =
    case arg1 of
        PaymentDone did ->
            Just did.donationId

        _ ->
            Nothing


getSteps : Model -> List ( Translation, Route )
getSteps model =
    ( { en = "Donate", de = "Spenden", fr = "Faire un don" }
    , Home
    )
        :: (if Donation.hasReceipt model.donationForm then
                [ ( { en = "Receipt", de = "Quittung", fr = "Reçu" }
                  , ReceiptRoute
                  )
                ]

            else
                []
           )
        ++ [ ( { en = "Payment", de = "Zahlung", fr = "Paiement" }
             , Payment
             )
           , ( { en = "Done", de = "Fertig", fr = "Terminé" }
             , Done (Maybe.withDefault "" (getDonationId model.paymentStatus))
             )
           ]


step : { index : Int, description : Translation, selected : Bool, onClick : Msg, done : Bool, disabled : Bool } -> Model -> Html Msg
step props model =
    div
        [ if props.disabled then
            class ""

          else
            class "cursor-pointer"
        , class "mb-0" -- Needed for description
        , Html.Events.onClick props.onClick
        ]
        [ div
            [ class "relative flex flex-col justify-center items-center space-y-1"
            ]
            [ div
                [ class "flex justify-center items-center w-8 h-8 rounded-full"
                , if props.selected then
                    class "bg-primary text-white hover:bg-primary-dark"

                  else if props.disabled then
                    class "bg-gray-100 dark:bg-gray-800 text-gray-500 dark:text-gray-400"

                  else
                    class "bg-gray-100 dark:bg-gray-800 text-gray-500 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700"
                ]
                [ div
                    [ class "text-xs font-bold leading-none"
                    ]
                    [ if props.done then
                        UI.icon [ class "w-[12px] text-primary" ] "check"

                      else
                        Html.text (String.fromInt (props.index + 1))
                    ]
                ]
            , div
                [ class "text-xs text-gray-500 dark:text-gray-400 absolute text-center -bottom-5 w-20"
                ]
                [ text model props.description
                ]
            ]
        ]


backButton : Route -> Model -> Html Msg
backButton route model =
    button
        [ class "underline text-sm text-gray-500 hover:text-gray-700"
        , focusVisible
        , Html.Events.onClick (Navigate route)
        ]
        [ text model { en = "Back", de = "Zurück", fr = "Retour" } ]


methodToggles : Model -> Html Msg
methodToggles model =
    div
        [ class "flex flex-col space-y-2"
        ]
        [ labelEl model
            { en = "Choose payment method"
            , de = "Zahlungsmethode wählen"
            , fr = "Choisissez le mode de paiement"
            }
        , div
            [ class "flex flex-row space-x-4 items-center cursor-pointer w-full"
            ]
            [ pill model
                { selector = Bitcoin
                , text = "On-chain"
                , selected = bitcoinLogoWhite
                , selectedStyle = "bg-[#F7931A] text-white"
                , unselected = bitcoinLogoGray
                , onClicki = SetPaymentMethod Bitcoin
                }
            , pill model
                { selector = Lightning
                , text = "Lightning"
                , selected = bitcoinLnLogoBlack
                , selectedStyle = "bg-[#FFE949] text-gray-800"
                , unselected = bitcoinLnLogoGray
                , onClicki = SetPaymentMethod Lightning
                }
            ]
        ]


pill : { a | donationForm : { b | paymentMethod : c } } -> { d | selector : c, text : String, selectedStyle : String, selected : List (Html.Attribute msg) -> Html e, unselected : List (Html.Attribute f) -> Html e, onClicki : e } -> Html e
pill model { selector, text, selectedStyle, selected, unselected, onClicki } =
    div
        [ class "flex flex-row space-x-2 items-center py-1.5 rounded-full text-sm font-medium px-4 text-center w-full "
        , classList [ ( "border border-gray-200 text-gray-400", model.donationForm.paymentMethod /= selector ) ]
        , classList
            [ ( selectedStyle, model.donationForm.paymentMethod == selector )
            ]
        , onClick onClicki
        ]
        [ if model.donationForm.paymentMethod == selector then
            selected [ class "h-10" ]

          else
            unselected [ class "h-10" ]
        , div
            [ class "text-center w-full pr-5"
            ]
            [ Html.text text
            ]
        ]


toggle : { b | env : Language.LanguageContext a } -> List { text : String } -> { text : String } -> msg -> Html msg
toggle model options selected onToggle =
    div
        [ class "flex flex-col space-y-2"
        ]
        [ labelEl model
            { en = "Choose payment method"
            , de = "Zahlungsmethode wählen"
            , fr = "Choisissez le mode de paiement"
            }
        , div
            [ class "flex flex-row space-x-4 items-center cursor-pointer w-full"
            , onClick onToggle
            ]
            [ div
                [ class "flex-1"
                ]
                []
            , div
                [ class "flex flex-row space-x-2 items-center"
                , class "border-2 rounded-full border-gray-300 dark:border-gray-700"
                , class "bg-gray-300 dark:bg-gray-700"
                ]
              <|
                List.map
                    (\o ->
                        div
                            [ class "w-10 p-1.5 rounded-full"
                            , classList [ ( "bg-gray-100 dark:bg-gray-800", o == selected ) ]
                            , classList [ ( "opacity-50", o /= selected ) ]
                            ]
                            [ Html.text o.text
                            ]
                    )
                    options
            ]
        ]


{-| Round to the nearest n.
-}
roundTo : Int -> Int -> Int
roundTo n x =
    (x + n - 1) // n * n


digits : Int -> Int
digits x =
    if x < 10 then
        1

    else
        1 + digits (x // 10)


roundButton : Model -> Html Msg
roundButton model =
    let
        form =
            model.donationForm

        amount =
            getAmount form.amount.value

        rounded =
            amount
                |> roundTo (10 ^ digits amount // 100)

        roundedSatoshi =
            amount
                |> roundTo (21 * (10 ^ digits (atMost (getMaxAmount model) amount)) // 10)
    in
    div
        [ class "flex flex-row space-x-2 justify-center items-center"
        ]
        [ -- A very small button to round the value to a nice number
          button
            [ class "text-xs text-gray-500 hover:text-gray-700"
            , focusVisible
            , Html.Events.onClick <| UpdateDonationForm { form | amount = setValue (CustomAmount (String.fromInt rounded)) }

            -- on right click
            , Html.Events.custom "contextmenu" <|
                Decode.succeed
                    { stopPropagation = True
                    , preventDefault = True
                    , message = UpdateDonationForm { form | amount = setValue (CustomAmount (String.fromInt roundedSatoshi)) }
                    }
            ]
            [ text model
                { en = "Round"
                , de = "Aufrunden"
                , fr = "Arrondir"
                }
            ]
        ]


atMost : Int -> Int -> Int
atMost =
    min


viewHome : Model -> Client -> ExchangeRates -> Html Msg
viewHome model client exchangeRates =
    let
        form =
            model.donationForm

        predefinedAmounts =
            [ 21000, 210000, 420000 ]
    in
    div [ class "space-y-8" ]
        [ Validated.wrap
            (\currentAmount ->
                div [ class "flex flex-col space-y-4" ]
                    [ div
                        [ class "flex flex-row space-x-4"
                        ]
                        [ labelEl model
                            { en = "Amount"
                            , de = "Betrag"
                            , fr = "Montant"
                            }
                        , roundButton model
                        ]
                    , div [ class "flex justify-between space-x-2" ] <|
                        List.map
                            (\amount ->
                                coin
                                    { selected = getAmount currentAmount
                                    , amount = amount
                                    , onClick = \int -> UpdateDonationForm { form | amount = setValue (Amount int) }
                                    }
                            )
                            predefinedAmounts
                            ++ [ coinCustom
                                    model.env
                                    { selected = isCustomAmount currentAmount
                                    , amount = getCustomAmount currentAmount
                                    , onClick = Noop
                                    , onInput = \str -> UpdateDonationForm { form | amount = setValue (CustomAmount str) }
                                    }
                               , if False then
                                    Amount.viewSatisfactionEmoji
                                        (getPaymentConstrains model client)
                                        (getAmount currentAmount)

                                 else
                                    UI.none
                               ]
                    , slider
                        { value = String.fromInt (getAmount form.amount.value)
                        , constrains = getPaymentConstrains model client
                        , onInput = \str -> UpdateDonationForm { form | amount = setValue (CustomAmount str) }
                        }
                    ]
            )
            form.amount
        , Amount.viewExchangeRate model
            { from = SAT
            , to = client.config.defaultCurrency
            }
            exchangeRates
            (Amount.getAmount form.amount.value |> toFloat)
        , methodToggles model
        , if client.config.enableReceipt then
            if exceedsThresholdAmount client exchangeRates model.donationForm then
                div
                    [ class "italic flex gap-3 items-center text-primary font-medium"
                    ]
                    [ icon [ class "text-primary font-medium" ] "circleExclamation"
                    , text model
                        { en = "Donations of this value require a donation receipt."
                        , de = "Spenden über diesen Wert benötigen eine Spendenquittung."
                        , fr = "Les dons de cette valeur nécessitent un reçu de don."
                        }
                    ]

            else
                checkboxWithDescription_ model
                    { label =
                        { en = "I want to receive a donation receipt"
                        , de = "Ich möchte eine Spendenquittung erhalten"
                        , fr = "Je souhaite recevoir un reçu de don"
                        }
                    , checked = Donation.hasReceipt form
                    , onToggle = ToggleReceipt
                    , description =
                        { en = "You can get a receipt for your donation in case you want to claim it for tax purposes."
                        , de = "Sie können eine Quittung für Ihre Spende erhalten, falls Sie diese steuerlich geltend machen möchten."
                        , fr = "Vous pouvez obtenir un reçu pour votre don au cas où vous souhaiteriez le réclamer à des fins fiscales."
                        }
                    }

          else
            UI.none
        ]


viewReceiptForm : Model -> Html Msg
viewReceiptForm model =
    let
        form =
            model.donationForm
    in
    case form.receipt of
        Nothing ->
            UI.none

        Just receipt ->
            div
                [ class "space-y-4"
                ]
                [ -- Donator information
                  div
                    [ class "space-y-2"
                    ]
                    [ wrap
                        (\fullname ->
                            input model
                                { label = { en = "Full name", de = "Vollständiger Name", fr = "Nom complet" }
                                , placeholder = "Satoshi Nakamoto"
                                , value = fullname
                                , onInput = \str -> UpdateDonationForm { form | receipt = Just { receipt | fullname = setValue str } }
                                }
                        )
                        receipt.fullname
                    , wrap
                        (\fulladdress ->
                            UI.textarea model
                                { label = { en = "Full address", de = "Vollständige Adresse", fr = "Adresse complète" }
                                , placeholder = "Straße des 3. Januars\n00021 Berlin\nDeutschland"
                                , value = fulladdress
                                , onInput = \str -> UpdateDonationForm { form | receipt = Just { receipt | fulladdress = setValue str } }
                                }
                        )
                        receipt.fulladdress
                    , wrap
                        (\email ->
                            input model
                                { label = { en = "Email", de = "E-Mail", fr = "Email" }
                                , placeholder = "satoshi@nakamo.to"
                                , value = email
                                , onInput = \str -> UpdateDonationForm { form | receipt = Just { receipt | email = setValue str } }
                                }
                        )
                        receipt.email
                    ]

                -- Legal information
                , wrap
                    (\privateAsset ->
                        checkboxWithDescription_ model
                            -- The asset is a personal asset and has been in my possession for over a year
                            { label =
                                { en = "The asset is a personal asset and has been in my possession for over a year"
                                , de = "Das Asset ist ein persönliches Asset und war länger als ein Jahr in meinem Besitz"
                                , fr = "L'actif est un actif personnel et est en ma possession depuis plus d'un an"
                                }
                            , checked = privateAsset
                            , onToggle =
                                \t ->
                                    UpdateDonationForm
                                        { form
                                            | receipt =
                                                Just
                                                    ({ receipt
                                                        | privateAsset = setValue t
                                                        , minHoldTime = setValue t
                                                     }
                                                        |> Data.Receipt.clearNonLegalFields
                                                    )
                                        }
                            , description =
                                { en = "I hereby confirm that the asset I am donating is of a personal nature and not a business asset. I confirm that the in-kind donation has been in my possession for over a year."
                                , de = "Ich bestätige hiermit, dass das Wertvermögen, das ich spende, von persönlicher Natur ist und kein Geschäftsvermögen darstellt. Ich bestätige, dass die Sachspende länger als ein Jahr in meinem Besitz war."
                                , fr = "Je confirme par la présente que l'actif que je fais don est de nature personnelle et non un actif commercial. Je confirme que le don en nature est en ma possession depuis plus d'un an."
                                }
                            }
                    )
                    receipt.privateAsset
                , Data.Receipt.maybeShowSupporEmailForLegal model receipt
                ]



-- MISC


logoWm : Html msg
logoWm =
    Html.img
        [ Html.Attributes.src <| "/assets/logo/logo-wordmark-default.png"
        , class "w-[100px]"
        ]
        []


logo : Html msg
logo =
    Html.img
        [ Html.Attributes.src <| "/assets/logo/logo-default.png"
        , class "w-[100px]"
        ]
        []


bitcoinBtcLogo : List (Html.Attribute msg) -> Html msg
bitcoinBtcLogo attrs =
    Html.img
        ((Html.Attributes.src <| "/assets/logo/bitcoin-btc-logo.svg")
            :: attrs
        )
        []


bitcoinLnLogo : List (Html.Attribute msg) -> Html msg
bitcoinLnLogo attrs =
    Html.img
        ((Html.Attributes.src <| "/assets/logo/bitcoin-ln-logo.svg")
            :: attrs
        )
        []


bitcoinLnLogoBlack : List (Html.Attribute msg) -> Html msg
bitcoinLnLogoBlack attrs =
    Html.img
        ((Html.Attributes.src <| "/assets/logo/bitcoin-ln-logo-icon-black.svg")
            :: attrs
        )
        []


bitcoinLnLogoGray : List (Html.Attribute msg) -> Html msg
bitcoinLnLogoGray attrs =
    Html.img
        ((Html.Attributes.src <| "/assets/logo/bitcoin-ln-logo-icon-gray.svg")
            :: attrs
        )
        []


bitcoinLogoWhite : List (Html.Attribute msg) -> Html msg
bitcoinLogoWhite attrs =
    Html.img
        ((Html.Attributes.src <| "/assets/logo/bitcoin-btc-logo-icon-white.svg")
            :: attrs
        )
        []


bitcoinLogoGray : List (Html.Attribute msg) -> Html msg
bitcoinLogoGray attrs =
    Html.img
        ((Html.Attributes.src <| "/assets/logo/bitcoin-btc-logo-icon-gray.svg")
            :: attrs
        )
        []



-- STYLES


getStyleSheet : String
getStyleSheet =
    """
    html {
    --primary-color: #618fe8;
    --primary-color-darker: #2c55a8;
    --secondary-color: #f9a136;
    --secondary-color-darker: #f89012;
    --shade1: #FBFBFA;
    --shade2: ##344145;
    }

    /* dark mode */

    @media (prefers-color-scheme: dark) {
        html {
            --primary-color: #618fe8;
            --primary-color-darker: #2c55a8;
            --secondary-color: #f9a136;
            --secondary-color-darker: #f89012;
            --shade1: #111617;
            --shade2: #FBFBFA;
        }
    }
    
    """
